<template>
  <div class="menu">
    <el-menu :default-active="$route.meta.elmenu || $route.path" class="el-menu-vertical-demo" @open="handleOpen"
      @close="handleClose" background-color="#f5f5f5" text-color="#444444" active-text-color="#444444"
      :unique-opened="true" :router="true">
   <!--   <el-submenu index="1">  owen 20230907  用户登录直接访问产品列表
        <template slot="title">
          <span>个人中心</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/editpwd">密码修改</el-menu-item>
          <el-menu-item index="/address">我的地址</el-menu-item>
          <el-menu-item index="/footprint">我的足迹</el-menu-item>
          <el-menu-item index="/addacount">账号管理</el-menu-item> -->
       <!--   <el-menu-item index="/countlist" v-if="commonData.group_id == 1">账号管理</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="2">
        <template slot="title">
          <span>合同中心</span>
        </template>
        <el-menu-item-group>
          <el-menu-item :index="
            userCenterData &&
              userCenterData.company_info &&
              userCenterData.company_info.status == 1
              ? '/certificationinfo'
              : '/certification'
          ">认证管理</el-menu-item>
          <el-menu-item index="/contract">合同管理</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">
          <span>交易中心</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/favlist">我的收藏</el-menu-item>
          <el-menu-item index="/cart">我的购物车</el-menu-item>
          <el-menu-item index="/orderlist">我的订单</el-menu-item>
          <el-menu-item index="/evaluatelist">评价管理</el-menu-item>
          <el-menu-item index="/saleslist">售后订单</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="4">
        <template slot="title">
          <span>采购中心</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="1-1">账单管理</el-menu-item> -->
         <!-- <el-menu-item index="/purchase">采购列表</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="5">
        <template slot="title">
          <span>财务中心</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/financial">对账管理</el-menu-item>
          <el-menu-item index="/remitmoney">汇款管理</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="6">
        <template slot="title">
          <span>发票中心</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/invoice">我的发票</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
	  <el-submenu index="7">
	    <template slot="title">
	      <span>申请中心</span>
	    </template>
	    <el-menu-item-group>
	      <el-menu-item index="/applysettlementrecord">申请对账</el-menu-item>
		  <el-menu-item index="/applystatusrecord">额度状态</el-menu-item>
		  <el-menu-item index="/applychangerecord">额度管理</el-menu-item>
	    </el-menu-item-group>
	  </el-submenu>
	  <el-submenu index="8">
	    <template slot="title">
	      <span>审批中心</span>
	    </template>
	    <el-menu-item-group>
	      <el-menu-item index="/approval" v-if="this.$store.getters.commonData.group_id == 1">审批管理</el-menu-item>
		  <el-menu-item index="/applysettlement">申请对账审批</el-menu-item>
	    </el-menu-item-group>
	  </el-submenu>
  -->

    <el-submenu index="9">
	    <template slot="title">
	      <span>产品方案</span>
	    </template>
	    <el-menu-item-group>
	      <el-menu-item index="/mygoodslist">我的产品方案</el-menu-item>
		  
	    </el-menu-item-group>
	  </el-submenu>
    <el-submenu index="10">
	    <template slot="title">
	      <span>登录密码</span>
	    </template>
	    <el-menu-item-group>
	      <el-menu-item index="/editpwd">密码修改</el-menu-item>
		  
	    </el-menu-item-group>
	  </el-submenu>
    </el-menu>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: "Sidebar",
  props: {},
  components: {},
  data() {
    return {
      userCenterData: {},
    };
  },
  computed: {
    ...mapGetters(["commonData"]),
  },
  watch: {},
  methods: {
    handleOpen(current) {
      if (current == 2) {
        this.getUserCenter();
      }
    },
    handleClose() { },
    getUserCenter() {
      this.$api.getUserCenterApi().then((res) => {
        this.userCenterData = res.data;
      });
    },
  },
  created() { },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
.menu {
  overflow: hidden;
  width: 248px;

  /deep/ .el-menu {
    border: none;
    padding: 15px 0;

    >li {
      border-bottom: 1px solid #e0e0e0;

      &.is-opened {

        &.el-menu-item,
        .el-submenu__title {



          &::before {
            // background-image: url("~@/assets/icon/icon-minus.png");
            content: "\e8c5";
            font-family: "iconfont";
            font-size: 20px;
            // width: 18px;
            // height: 18px;
            margin-right: 10px;
            color: $-color-primary;
          }
        }
      }

      .el-menu--inline {
        padding: 0;
      }

      &.el-menu-item,
      .el-submenu__title {
        height: 70px;
        line-height: 70px;
        font-size: 19px;
        padding: 0 0 0 27px !important;
        display: flex;
        align-items: center;

        &:hover {
          background-color: transparent !important;
        }


        &::before {
          content: "\e8c8";
          font-family: "iconfont";
          color: $-color-primary;
          font-size: 20px;
          // width: 18px;
          // height: 18px;
          margin-right: 10px;
          // // background-image: url("~@/assets/icon/icon-add.png");
          // background-size: 100%;
          // background-position: center;
        }

        i {
          display: none;
        }
      }

      &:last-child {
        border-bottom: none;
      }

      .el-menu-item-group {
        padding-bottom: 20px;

        .el-menu-item-group__title {
          padding: 0;
        }

        ul {

          // padding-bottom: 20px;
          .el-menu-item {
            font-size: 16px;
            line-height: 30px;
            height: 30px;
            padding: 0 0 0 60px !important;

            &.is-active {
              color: $-color-primary  !important
            }

            &:hover {
              background-color: inherit !important;
              color: $-color-primary  !important;
            }
          }
        }
      }
    }
  }
}
</style>